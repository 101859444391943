import {
  useRouteLocationWithLocale,
  useRouter,
  useRuntimeConfig,
} from '#imports'

import type { Orderline } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineListResponse'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { openModal } from '@ds/components/ModalBase'

import { SALES_CUSTOMER_CARE_SCOPE } from '@/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '@/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'
import { CUSTOMER_CARE } from '@/scopes/customer-care/route-names'
import { PASTRAMI_CUSTOMER_CONVERSATION } from '@/scopes/customer-requests/routes'
import { REPAIR as ROUTES_REPAIR } from '@/scopes/repair/routes'
import { CUSTOMER_CONVERSATION } from '~/scopes/customer-conversation/route-names'
import { useBuildReviewCollectionLink } from '~/scopes/reviews/review-collection/composables/useBuildReviewCollectionLink'

import { ORDERLINE_CARD_MODAL } from '../OrderlineCard.constants'

export type OrderlineCardActionsConfig = {
  config: {
    [key in Orderline['actions'][number]['type']]: (
      item: Orderline['actions'][number],
    ) => {
      onClick?: ({
        orderlineId,
        publicId,
      }: {
        orderlineId: string | number
        publicId: string
      }) => Promise<void>
    }
  }
}

export const useOrderlineCardActionsConfig = (): OrderlineCardActionsConfig => {
  const injectLocale = useRouteLocationWithLocale()
  const { logFeatureError } = useSalesCustomerCareLogger()
  const router = useRouter()
  const tracking = useTracking()
  const { market } = useMarketplace()
  const { buildReviewCollectionLink } = useBuildReviewCollectionLink()

  const { FF_CUSTOMER_CONVERSATION_ENABLED_BY_COUNTRY } =
    useRuntimeConfig().public
  const isCustomerConversationEnabled = (
    FF_CUSTOMER_CONVERSATION_ENABLED_BY_COUNTRY as string
  )?.includes(market.countryCode)

  const defineRouterLink = ({
    name,
    params,
  }: {
    name: string
    params: Record<string, string | number>
  }) => {
    return toNuxtLink(
      injectLocale({
        type: 'internal',
        name,
        params,
      }),
    )
  }

  return {
    config: {
      cancel_order_before_validation: () => ({
        onClick: async () => {
          /*
           * https://backmarket.atlassian.net/browse/SCAN-5870
           */
          logFeatureError({
            errorDetail:
              "The 'cancel order before validation' action is disabled and cannot be clicked.",
            errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.myOrders} Error on my orders cta config`,
            featureName: 'my_oders_cta_config',
          })
        },
      }),
      cancel_order_pending_payment: () => ({
        onClick: async ({ orderlineId }) => {
          openModal(
            `${ORDERLINE_CARD_MODAL.cancelOrderWithPendingPayment}-${orderlineId}`,
          )
        },
      }),
      diagnosis: (item) => ({
        onClick: async () => {
          if (item?.link && 'params' in item.link) {
            const { orderlineId } = item.link.params

            await router.push(
              defineRouterLink({
                name: CUSTOMER_CARE.DIAGNOSIS.SELF,
                params: { orderlineId },
              }),
            )
          }
        },
      }),
      diagnosis_new_issue: (item) => ({
        onClick: async () => {
          if (item?.link && 'params' in item.link) {
            const { orderlineId } = item.link.params

            await router.push(
              defineRouterLink({
                name: CUSTOMER_CARE.DIAGNOSIS.SELF,
                params: { orderlineId },
              }),
            )
          }
        },
      }),
      get_more_help: (item) => ({
        onClick: async () => {
          if (item?.link && 'params' in item.link) {
            const { resolutionId } = item.link.params

            await router.push(
              defineRouterLink({
                name: PASTRAMI_CUSTOMER_CONVERSATION.RESOLUTION,
                params: { resolutionId },
              }),
            )
          }
        },
      }),
      join_conversation: (item) => ({
        onClick: async () => {
          if (item?.link && 'params' in item.link) {
            const { resolutionId } = item.link.params

            const route = isCustomerConversationEnabled
              ? CUSTOMER_CONVERSATION.RESOLUTION
              : PASTRAMI_CUSTOMER_CONVERSATION.RESOLUTION

            await router.push(
              defineRouterLink({
                name: route,
                params: { resolutionId },
              }),
            )
          }
        },
      }),
      live_chat: (item) => ({
        onClick: async () => {
          if (item?.link && 'params' in item.link) {
            const { resolutionId } = item.link.params

            await router.push(
              defineRouterLink({
                name: PASTRAMI_CUSTOMER_CONVERSATION.RESOLUTION,
                params: { resolutionId },
              }),
            )
          }
        },
      }),
      order_bill: (item) => ({
        onClick: async () => {
          if (!isEmpty(item.link)) {
            window.open(item.link.href, '_blank', 'noopener,noreferrer')
          }
        },
      }),
      pending_order_bill: () => ({}),
      rate_order: () => ({
        onClick: async ({ orderlineId, publicId }) => {
          tracking.trackClick({
            name: 'leave_review',
            zone: 'my_oders',
          })

          // Due to issues encountered in SCAN-6093 we use the
          // internally resolved link instead of the one from API.
          const routeLocation = defineRouterLink(
            buildReviewCollectionLink({ orderlineId, publicId }),
          )

          await router.push(routeLocation)
        },
      }),
      sale_certificate: (item) => ({
        onClick: async () => {
          if (!isEmpty(item.link)) {
            window.open(item.link.href, '_blank', 'noopener,noreferrer')
          }
        },
      }),
      see_return_confirmation: (item) => ({
        onClick: async () => {
          if (item?.link && 'params' in item.link) {
            const { resolutionId } = item.link.params

            await router.push(
              defineRouterLink({
                name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_RETURN_CONFIRMATION,
                params: { resolutionId },
              }),
            )
          }
        },
      }),
      track_parcel: (item) => ({
        onClick: async () => {
          if (!isEmpty(item.link)) {
            window.open(item.link.href, '_blank', 'noopener,noreferrer')
          }
        },
      }),
      track_repair_picked_up: (item) => ({
        onClick: async () => {
          if (item?.link && 'params' in item.link) {
            const { resolutionId } = item.link.params

            const route = isCustomerConversationEnabled
              ? CUSTOMER_CONVERSATION.RESOLUTION
              : PASTRAMI_CUSTOMER_CONVERSATION.RESOLUTION

            await router.push(
              defineRouterLink({
                name: route,
                params: { resolutionId },
              }),
            )
          }
        },
      }),
      track_repair_not_picked_up: (item) => ({
        onClick: async () => {
          if (item?.link && 'params' in item.link) {
            const { resolutionId } = item.link.params

            await router.push(
              defineRouterLink({
                name: CUSTOMER_CARE.RESOLUTION.RESOLUTION_RETURN_CONFIRMATION,
                params: { resolutionId },
              }),
            )
          }
        },
      }),
      track_shop_repair: (item) => ({
        onClick: async () => {
          if (item?.link && 'params' in item.link) {
            const { repairId } = item.link.params

            await router.push(
              defineRouterLink({
                name: ROUTES_REPAIR.TRACKING,
                params: { repairId },
              }),
            )
          }
        },
      }),
    },
  }
}
