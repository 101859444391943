<template>
  <ul
    class="flex overflow-x-auto md:flex-wrap md:overflow-hidden"
    data-test="rating-details"
  >
    <li
      v-for="{ identifier, rate } in supportedDetails"
      :key="identifier"
      :aria-labelledby="`${identifier} ${identifier}-rate`"
      class="ml-32 flex w-3/4 shrink-0 flex-col first:ml-0 md:m-0 md:w-1/4"
    >
      <RevCard
        class="border-static-default-low shadow-short rounded-xs border border-solid p-8 md:border-none md:px-0 md:shadow-none"
      >
        <p :id="identifier" class="caption mb-8">
          {{
            i18n(
              detailTitleByIdentifier[
                identifier as ProductRatingDetailsIdentifierWithoutAttachments
              ],
            )
          }}
        </p>

        <RatingDots
          :id="`${identifier}-rate`"
          class="w-auto"
          :rate="rate as number"
        />
      </RevCard>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type {
  ProductRatingDetailsIdentifierWithoutAttachments,
  ReviewDetailsLine,
} from '@backmarket/http-api/src/api-specs-reviews/types/review'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'

import { detailTitleByIdentifier } from './RatingDetails.constants'
import RatingDots from './RatingDots.vue'

const props = defineProps<{ details: ReviewDetailsLine[] }>()

const i18n = useI18n()

const supportedDetails = computed(() =>
  props.details.filter(({ identifier }) =>
    Object.keys(detailTitleByIdentifier).includes(identifier),
  ),
)
</script>
