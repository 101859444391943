<template>
  <CoveragePaymentAdvertisement
    v-if="coveragePaymentAdvertisement && coveragePaymentFailurePolicyId"
    class="my-24"
    data-test="coverage-payment-advertisement"
    :policy-id="coveragePaymentFailurePolicyId"
  />

  <PaymentAdvertisement
    v-if="paymentMethod"
    :base-price="orderline.billing.price"
    class="bg-surface-default-hi rounded-lg p-12"
    data-test="payment-advertisment"
    :payment-methods="[paymentMethod]"
    spot="orders"
    text-size="1"
  />

  <Product class="pt-24" :product-data="productData" />
  <Shipping class="pt-56" :shipping-data="shippingData" />

  <Coverages class="pt-56" :coverages="props.orderline.coverages" />
  <DocumentActions
    :actions-data="props.orderline.detailedActions"
    class="pt-56"
    :order-id="props.orderline.orderId"
  />
  <NewReview
    v-if="canBeReviewed"
    class="pt-56"
    :orderline-id="props.orderline.orderlineId"
    :public-id="props.orderline.publicId"
  />
  <ReviewItemOrderlineWrapper
    v-if="!canBeReviewed"
    class="pt-56"
    :public-id="props.orderline.publicId"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { OrderlineDetailed } from '@backmarket/http-api/src/api-specs-my-orders-api/types/orderlineDetailsResponse'
import { PaymentAdvertisement } from '@backmarket/nuxt-module-payment/advertising'
import { useMarketPaymentMethods } from '@backmarket/nuxt-module-payment/methods/composables/useMarketPaymentMethods'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { SALES_CUSTOMER_CARE_SCOPE } from '~/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '~/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'
import ReviewItemOrderlineWrapper from '~/scopes/reviews/reviews-display/components/ReviewItemOrderline/ReviewItemOrderlineWrapper.vue'

import CoveragePaymentAdvertisement from './components/CoveragePaymentAdvertisement/CoveragePaymentAdvertisement.vue'
import Coverages from './components/Coverages/Coverages.vue'
import DocumentActions from './components/DocumentActions/DocumentActions.vue'
import NewReview from './components/NewReview/NewReview.vue'
import Product from './components/Product/Product.vue'
import Shipping from './components/Shipping/Shipping.vue'
import {
  orderlineCanBeReviewed,
  orderlineToProductData,
  orderlineToShippingData,
} from './utils/dataMapping'

const props = defineProps<{
  orderline: OrderlineDetailed
}>()

const { data: marketPaymentMethods } = useMarketPaymentMethods()
const { logFeatureError } = useSalesCustomerCareLogger()

const coveragePaymentAdvertisement = computed(() => {
  const allCoverages = props.orderline.coverages
    .map((item) => item.coverages)
    .flat()

  return allCoverages.find((coverage) => coverage.isPaymentNeeded === true)
})

const coveragePaymentFailurePolicyId = computed(() => {
  if (coveragePaymentAdvertisement.value) {
    const policyId =
      coveragePaymentAdvertisement.value?.failingOccurrencesLink?.params
        .policyUuid

    if (!isEmpty(policyId)) {
      return policyId
    }
    logFeatureError({
      errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.myOrders} coverage payment failure policy Id undefined`,
      errorDetail: {
        availableParams:
          coveragePaymentAdvertisement.value?.failingOccurrencesLink?.params,
        targetedPolicyParams: 'policyUuid',
        currentValue: policyId,
      },
      featureName: 'my_orders_coverage_payment_failure_policy_id_undefined',
    })
  }

  return undefined
})

const paymentMethod = computed(function joinPaymentMethod() {
  const { bmCode } = props.orderline.billing.paymentMethod

  if (marketPaymentMethods.value === null || bmCode === undefined) {
    return null
  }

  return marketPaymentMethods.value.find(
    (marketPaymentMethod) => marketPaymentMethod.bmCode === bmCode,
  )
})
const shippingData = orderlineToShippingData(props.orderline)
const productData = orderlineToProductData(props.orderline)

const canBeReviewed = computed(() =>
  orderlineCanBeReviewed(props.orderline.detailedActions),
)
</script>
